import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "p-10 mx-auto max-w-4xl bg-white rounded-lg" }
const _hoisted_2 = {
  "data-dusk": "patient-alternative-id-header",
  class: "flex flex-row items-center justify-between"
}
const _hoisted_3 = { class: "text-xl font-bold leading-tight text-gray-900" }
const _hoisted_4 = {
  "data-dusk": "patient-merged-date",
  class: "text-base text-gray-600"
}
const _hoisted_5 = {
  key: 0,
  class: "grid grid-cols-1 gap-y-8 gap-x-6 mt-10 md:grid-cols-2"
}
const _hoisted_6 = ["data-dusk"]
const _hoisted_7 = { class: "text-gray-600" }
const _hoisted_8 = { class: "text-gray-900" }
const _hoisted_9 = { class: "flex justify-end mt-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_BaseModal = _resolveComponent("BaseModal")!

  return (_openBlock(), _createBlock(_component_BaseModal, {
    "close-on-background-click": true,
    onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close')))
  }, {
    default: _withCtx(() => [
      _createElementVNode("section", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.$t('custom.uhb.patient.alternative-id')) + _toDisplayString(_ctx.mrn), 1)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('custom.uhb.patient.merged-date') + ': ') + _toDisplayString(_ctx.mergedDate), 1)
        ]),
        (_ctx.patientDetails)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.patientDetails, (item, index) => {
                return (_openBlock(), _createElementBlock("div", { key: index }, [
                  _createElementVNode("div", {
                    "data-dusk": _ctx.duskIdentifier(item.label),
                    class: "flex flex-col space-y-2"
                  }, [
                    _createElementVNode("div", _hoisted_7, _toDisplayString(item.label), 1),
                    _createElementVNode("div", _hoisted_8, _toDisplayString(item.value), 1)
                  ], 8, _hoisted_6)
                ]))
              }), 128))
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_9, [
          _createVNode(_component_BaseButton, {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('platform.common.close')), 1)
            ]),
            _: 1
          })
        ])
      ])
    ]),
    _: 1
  }))
}