
import { Vue, Options } from 'vue-class-component';
import { BaseModal, BaseButton } from '@/lib/components';
import { IOption } from '@/lib';

@Options({
  components: { BaseModal, BaseButton },
  props: {
    patientDetails: {
      type: Array,
      default: null
    },
    mrn: {
      type: String,
      default: null
    },
    mergedDate: {
      type: String,
      default: null
    }
  }
})
export default class MinorPatientDetailModal extends Vue {
  patientDetails!: Array<IOption> | null;

  duskIdentifier(label: string): string {
    const duskIdentifier = label.replace(/ /g, '-').toLowerCase();
    if (duskIdentifier.startsWith('patient')) {
      return duskIdentifier;
    }
    return `patient-${duskIdentifier}`;
  }
}
